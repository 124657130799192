<template>
  <div class="dashboard_component_inner">
    <div class="onboarding_boxes">
      <div class="onboarding_header">
        <div class="img_sec">
          <img
            src="/assets/img/hello.png"
            alt=""
          >
        </div>
        <div class="content_sec">
          <h2>Hi {{ getProfile.stripped_full_name }}, Welcome to {{ getSiteDetails.agency_name }}!</h2>
          <p>Let's get you started in a few simple steps. It won't take more than a couple of
            minutes
            to create your first optimized and shortened {{ getSiteDetails.agency_name }} link. </p>
        </div>
        <div class="hide btn_sec">
          <button class="btn---cta btn-blue btn-round">
            <span>Getting Started</span>
          </button>
          <p>Watch the introduction video</p>
        </div>
      </div>
      <div class="onboarding_box clear">

        <div
          class="layout_box "
          :class="{completed:(getProfile.onboarding_data['brand'] == true)}"
        >
          <div class="box_inner">
            <div class="step_number">
              <span class="circle">
                <span>1</span>
              </span>
            </div>
            <div class="content">
              <!-- <button
                @click.prevent="gettingStarted('createBrand')"
                v-if="getProfile.onboarding_data['brand'] == true"
                class="btn---cta btn-blue btn-round"
              >
                <span>Create a Brand</span>
                <i class="far fa-check-circle"></i>
              </button>

              <button
                v-else
                @click.prevent="gettingStarted('createBrand')"
                class="btn---cta btn-blue btn-round"
              ><span>Create a Brand</span></button> -->

              <ui-button
                :type="'add'"
                :color="'#ffffff'"
                :bgColor="'#3988fe'"
                :text="'Create a Brand'"
                :icon="'far fa-check-circle'"
                :iconPosition="'default'"
                :iconFontSize="'14px'"
                v-if="getProfile.onboarding_data['brand'] == true"
                v-on:click="gettingStarted('createBrand')"
              />
              <ui-button
                :type="'add'"
                :color="'#ffffff'"
                :bgColor="'#3988fe'"
                :text="'Create a Brand'"
                v-else
                v-on:click="gettingStarted('createBrand')"
              />
              <p>Brands help you promote your identity and stay organized.
                It will be shown with a photo and a name along with your call-to-action
                message.</p>
            </div>
          </div>
        </div>

        <div
          class="layout_box"
          :class="{'disabled':getProfile.onboarding_data['brand'] == false,'completed':getProfile.onboarding_data['cta'] == true}"
        >
          <div class="box_inner">
            <div class="step_number">
              <span class="circle">
                <span>2</span>
              </span>
            </div>
            <div class="content">

              <!-- <button
                @click.prevent="gettingStarted('createCTA')"
                v-if="getProfile.onboarding_data['cta'] == true"
                class="btn---cta btn-blue btn-round"
              >
                <span>Create a Campaign</span>
                <i class="far fa-check-circle"></i>
              </button> -->
              <!-- <button
                v-else
                @click.prevent="gettingStarted('createCTA')"
                class="btn---cta btn-blue btn-round"
              >
                <span>Create a Campaign</span>
              </button> -->

              <ui-button
                :type="'add'"
                :color="'#ffffff'"
                :bgColor="'#3988fe'"
                :text="'Create a Campaign'"
                :icon="'far fa-check-circle'"
                :iconPosition="'default'"
                :iconFontSize="'14px'"
                v-on:click="gettingStarted('createCTA')"
                v-if="getProfile.onboarding_data['cta'] == true"
              />

              <ui-button
                :type="'add'"
                :color="'#ffffff'"
                :bgColor="'#3988fe'"
                :text="'Create a Campaign'"
                v-else
                v-on:click="gettingStarted('createCTA')"
              />

              <p>Create campaigns to use link shortener for tracking purposes,
                add call-to-actions for engagement or add retargeting pixels for
                building custom audiences.
                All the campaigns are associated with a brand to keep them grouped.</p>
            </div>
          </div>
        </div>

        <div
          class="layout_box"
          :class="{'disabled':(getProfile.onboarding_data['brand'] == false || getProfile.onboarding_data['cta'] == false),'completed':getProfile.onboarding_data['link'] == true}"
        >
          <div class="box_inner">
            <div class="step_number">
              <span class="circle">
                <span>3</span>
              </span>
            </div>
            <div class="content">
              <!-- <button
                @click.prevent="gettingStarted('createLink')"
                :disabled="true"
                v-if="getProfile.onboarding_data['link'] == true"
                class="btn---cta btn-blue btn-round"
              >
                <span>Create your first Replug Link</span>
                <i class="far fa-check-circle"></i>
              </button>
              <button
                v-else
                @click.prevent="gettingStarted('createLink')"
                class="btn---cta btn-blue btn-round"
              >
                <span>Create your first Replug Link</span>
              </button> -->

              <ui-button
                :type="'add'"
                :color="'#ffffff'"
                :bgColor="'#3988fe'"
                :text="'Create your first ' +  getSiteDetails.agency_name  +' Link'"
                :icon="'far fa-check-circle'"
                :iconPosition="'default'"
                :iconFontSize="'14px'"
                :disabled="true"
                v-on:click="gettingStarted('createLink')"
                v-if="getProfile.onboarding_data['link'] == true"
              />
              <ui-button
                :type="'add'"
                :color="'#ffffff'"
                :bgColor="'#3988fe'"
                :text="'Create your first '+ getSiteDetails.agency_name +' Link'"
                v-else
                v-on:click="gettingStarted('createLink')"
              />

              <p>Select the campaign you’ve created to create shortened and optimized
                {{ getSiteDetails.agency_name }} links from any page/link on the web.
                Then, share the {{ getSiteDetails.agency_name }} links with your audience!</p>
            </div>
          </div>
        </div>

        <div
          class="layout_box"
          :class="{'disabled':getProfile.onboarding_data['brand'] == false}"
        >
          <div class="box_inner">
            <div class="step_number">
              <span class="circle">
                <span>4</span>
              </span>
            </div>
            <div class="content">
              <!-- <button
                @click.prevent="gettingStarted('createLink')"
                :disabled="true"
                v-if="getProfile.onboarding_data['link'] == true"
                class="btn---cta btn-blue btn-round"
              >
                <span>Create your first Replug Link</span>
                <i class="far fa-check-circle"></i>
              </button>
              <button
                v-else
                @click.prevent="gettingStarted('createLink')"
                class="btn---cta btn-blue btn-round"
              >
                <span>Create your first Replug Link</span>
              </button> -->

              <ui-button
                :type="'add'"
                :color="'#ffffff'"
                :bgColor="'#3988fe'"
                :text="'Create your first Bio Link'"
                :icon="'far fa-check-circle'"
                :iconPosition="'default'"
                :iconFontSize="'14px'"
                v-on:click="gettingStarted('createBioLink')"
                v-if="getProfile.onboarding_data['bio_link'] == true"
              />
              <ui-button
                :type="'add'"
                :color="'#ffffff'"
                :bgColor="'#3988fe'"
                :text="'Create your first Bio link'"
                v-else
                v-on:click="gettingStarted('createBioLink')"
              />

              <p>Optimize your internet presence, whether you’re a blogger, an artist or run a content platform. You get one link to house all the content you’re driving followers to. Share that link anywhere, like your Instagram bio, Facebook posts or Twitch profile.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default ({
  name: 'initial-onboarding-view'
})
</script>
